/* ✅ SKILLS SECTION */
#skills {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  #skills h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ffcc00;
    font-size: 2.8rem;
  }
  
  #skills p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    max-width: 100%;
    color: #000000;
  }
  
  /* ✅ SKILL BUTTON STYLING */
  .skills-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  /* Skill Button - Glassmorphic */
  .skill-button {
  
    font-family: 'Montserrat', sans-serif;
    background: rgba(255, 255, 255, 0.001);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 1rem;
    padding: 12px 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
    flex: 1 1 calc(25% - 20px); /* Makes sure 4 buttons fit in one row */
    min-width: 180px; /* Prevents buttons from getting too small */
    max-width: 220px; /* Ensures buttons don’t take full width */
    text-align: center;
  }
  
  
  /* Responsive Fix */
  @media (max-width: 1024px) {
    .skill-button {
      flex: 1 1 calc(33.33% - 20px); /* 3 buttons per row on smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .skill-button {
      flex: 1 1 calc(50% - 20px); /* 2 buttons per row on tablets */
    }
  }
  
  @media (max-width: 480px) {
    .skill-button {
      flex: 1 1 100%; /* Single button per row for mobile */
    }
  }
  
    /* ✅ Image Description Styling */
    .image-description {
      position: absolute;
      bottom: 20px; /* ✅ Adjust as needed */
      right: 20px; /* ✅ Position it on the right */
      background: rgba(0, 0, 0, 0.5); /* ✅ Semi-transparent background */
      color: white; /* ✅ Ensures visibility */
      padding: 8px 12px;
      border-radius: 5px;
      font-size: 0.9rem;
      font-family: 'Montserrat', sans-serif;
      text-align: right;
      max-width: 250px; /* ✅ Prevents overly wide text */
    }
    
    /* ✅ Responsive Tweaks */
    @media (max-width: 768px) {
      .image-description {
          font-size: 0.8rem;
          max-width: 200px;
          right: 10px;
          bottom: 10px;
      }
    }
    
    @media (max-width: 480px) {
      .image-description {
          font-size: 0.7rem;
          max-width: 180px;
          right: 5px;
          bottom: 5px;
      }
    }
    
  







  
  .skill-container {
    display: flex;
    flex-wrap: wrap; /* Ensures wrapping instead of overflowing */
    justify-content: center; /* Centers the buttons */
    gap: 10px; /* Reduces spacing between buttons */
    max-width: 90vw; /* Prevents excessive width */
    margin: 20px auto; /* Centers the container */
    padding: 10px;
    overflow: hidden; /* Prevents any extra content from leaking out */
    box-sizing: border-box;
  }
  
 

  
  
  
  /* Hover Effect */
  .skill-button:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px); /* Slight lift */
    box-shadow: 0 6px 15px rgba(255, 255, 255, 0.3);
  }
  
  /* Active Button Styling */
  .skill-button.active {
    background: rgba(255, 255, 255, 0.25); /* Slightly darker glass effect */
    border: 2px solid rgba(255, 255, 255, 0.6);
    transform: scale(1.05); /* Subtle zoom */
  }
  
  /* Smooth appearance animation */
  @keyframes fadeInGlass {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .skill-button {
    animation: fadeInGlass 0.4s ease-in-out;
  }
  
  
  .skill-details {
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.00010); /* Light transparent white */
    padding: 20px;
    border-radius: 12px;
    color: #ffffff;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
    backdrop-filter: blur(10px); /* Frosted glass effect */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle white border */
  }
  
  /* Slight scale effect when appearing */
  .skill-details.fade-in {
    transform: scale(1.02);
  }
  
  /* Title inside skill details */
  .skill-details h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    color: #c3c3c3;
    font-weight: 300;
  }
  
  /* Animation effect when details appear */
  @keyframes glassAppear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .skill-details {
    animation: glassAppear 0.5s ease-in-out;
  }
  #skills {
    width: 100vw; /* Full screen width */
    min-height: 100vh; /* Make each section at least full screen height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0vh auto; /* Remove all default margin */
    padding: 0; /* Remove padding to eliminate space */
    box-sizing: border-box;
  }
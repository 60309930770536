/* ✅ PROJECTS SECTION */
#projects {
  width: 80vw; /* ✅ Controls text width */
  max-width: 900px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* ✅ Left-aligns text */
  justify-content: center; /* ✅ Centers vertically */
  text-align: left; /* ✅ Ensures text remains left-aligned */
  margin: 0 auto; /* ✅ Centers the section itself */
  padding: 2rem;
  color: white;
}

/* ✅ PROJECTS HEADING */
#projects h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffcc00;
  font-size: 2.8rem;
}

/* ✅ PROJECTS TEXT */
#projects p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem; /* ✅ Default size for desktops */
  max-width: 100%;
  line-height: 1.6;
  text-align: left; /* ✅ Keeps text left-aligned */
}

/* ✅ MOBILE RESPONSIVENESS */
@media (max-width: 1024px) {
  #projects {
    width: 85vw;
    padding: 1.5rem;
  }
  #projects h2 {
    font-size: 2.8rem;
  }
  #projects p {
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  #projects {
    width: 90vw;
    padding: 1.2rem;
  }
  #projects h2 {
    font-size: 2.8rem;
  }
  #projects p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  #projects {
    width: 95vw;
    padding: 1rem;
  }
  #projects h2 {
    font-size: 2.4rem;
  }
  #projects p {
    font-size: 1rem;
  }
}

  /* ✅ Image Description Styling */
.image-description {
position: absolute;
bottom: 20px; /* ✅ Adjust as needed */
right: 20px; /* ✅ Position it on the right */
background: rgba(0, 0, 0, 0.5); /* ✅ Semi-transparent background */
color: white; /* ✅ Ensures visibility */
padding: 8px 12px;
border-radius: 5px;
font-size: 0.9rem;
font-family: 'Montserrat', sans-serif;
text-align: right;
max-width: 250px; /* ✅ Prevents overly wide text */
}

/* Add a small gap above the "Coming Soon" section */
.coming-soon-section {
margin-top: 2rem; /* Adjust as needed (e.g., 1rem, 3rem, etc.) */
margin-bottom: 0rem;
}

.coming-soon-text {
font-size: 1rem; /* Adjust as needed */
}

/* Add vertical spacing between consecutive project buttons */
.project-button + .project-button {
margin: 2rem; /* Adjust as desired */
margin-top: -2rem;
}


/* ✅ Responsive Tweaks */
@media (max-width: 768px) {
.image-description {
    font-size: 0.8rem;
    max-width: 200px;
    right: 10px;
    bottom: 10px;
}
}

@media (max-width: 480px) {
.image-description {
    font-size: 0.7rem;
    max-width: 180px;
    right: 5px;
    bottom: 5px;
}
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600&display=swap');

/* 🌍 GLOBAL STYLES */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: black;
  color: #e0e0e0;
  line-height: 1.6;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

/* ✅ HERO SECTION */
.parallax-hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  opacity: 0;
  animation: fadeIn 1.5s ease-out forwards;
}

.hero-section {
  position: relative; /* ✅ Ensures relative positioning */
}

.hero-image-description {
  position: absolute;
  bottom: 20px; /* ✅ Adjust as needed */
  right: 20px;
  background: rgba(0, 0, 0, 0.5); /* ✅ Semi-transparent background */
  color: white; /* ✅ Ensures visibility */
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-family: 'Montserrat', sans-serif;
  text-align: right;
  max-width: 500px; /* ✅ Prevents overly wide text */
}




/* ✅ HERO FADE-IN ANIMATION */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.parallax-hero.visible {
  opacity: 1;
}

/* ✅ HERO CONTENT */
.hero-content {
  font-size: 1.7rem;
  background: none;
  padding: 0rem;
  text-align: center;
  color: rgb(227, 227, 227);
  opacity: 1 !important;
  transform: translateY(-50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out 0.5s;
}

.hero-content h1 {
  font-size: 3.5rem;
  padding-bottom: 240px;
  margin-bottom: 300;
  transform: translateY(-10px);
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.hero-content p {
  padding-bottom: 7px;
  font-size: 1rem;
  display: block;
  margin: 5px 0;
  line-height: 1.1;
  transform: translateY(-260px);
}

.hero-content p:last-of-type {
  transform: translateY(-310px);
}


/* ✅ NAVBAR - Default (For Large Screens) */
.navbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%; /* ✅ Leaves gap on the sides */
  max-width: 650px; /* ✅ Slightly wider */
  background: transparent; /* Remove background */
  backdrop-filter: blur(10px);
  padding: 1rem 1.2rem; /* ✅ Slightly taller */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(110, 110, 110, 0.015); /* ✅ Soft glow effect */
  transition: opacity 0.5s ease-in-out, background 0.3s ease-in-out;
}

/* ✅ GLASSMORPHIC NAVBAR BUTTONS */
.navbar a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.6rem 1.2rem;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;

  /* ✅ TRUE GLASSMORPHIC EFFECT */
  background: rgba(0, 0, 0, 0.015);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  color: rgb(0, 0, 0);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.02);
}

/* ✅ Hover Effect */
.navbar a:hover {
  background: rgba(255, 255, 255, 0.025);
  transform: translateY(-2px); /* ✅ Slight lift */
}

/* ✅ ACTIVE LINK */
.navbar a.active-link {
  background: rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
}

/* ✅ MOBILE NAVBAR (For Small Screens) */
@media (max-width: 768px) {
  .navbar {
    width: 95%;
    padding: 0.8rem 1rem; /* ✅ Keeps it taller */
    justify-content: space-evenly;
  }

  .navbar a {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}

/* ✅ EVEN SMALLER SCREENS */
@media (max-width: 480px) {
  .navbar {
    width: 95%;
    padding: 0.7rem 0.8rem;
  }

  .navbar a {
    font-size: 0.85rem;
    padding: 0.5rem 0.9rem;
  }
}
/* ✅ ABOUT SECTION */
#about {
    width: 80vw; /* ✅ Controls text width */
    max-width: 900px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* ✅ Left-aligns text */
    justify-content: center; /* ✅ Centers vertically */
    text-align: left; /* ✅ Ensures text remains left-aligned */
    margin: 0 auto; /* ✅ Centers the section itself */
    padding: 2rem;
    color: white;
  }
  
  /* ✅ ABOUT HEADING */
  #about h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ffcc00;
    font-size: 2.8rem;
  }
  
  /* ✅ ABOUT TEXT */
  #about p {
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem; /* ✅ Default size for desktops */
    max-width: 100%;
    line-height: 1.6;
    text-align: left; /* ✅ Keeps text left-aligned */
}

#about p1 {
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem; /* ✅ Default size for desktops */
  max-width: 100%;
  line-height: 1.6;
  text-align: left; /* ✅ Keeps text left-aligned */
}
  
  /* ✅ MOBILE RESPONSIVENESS */
  @media (max-width: 1024px) {
    #about {
      width: 85vw; /* ✅ Slightly wider for better spacing */
      padding: 1.5rem;
    }
    #about h2 {
      font-size: 2.8rem; /* ✅ Slightly smaller on tablets */
    }
    #about p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    #about {
      width: 90vw; /* ✅ More space on smaller screens */
      padding: 1.2rem;
    }
    #about h2 {
      font-size: 2.8rem; /* ✅ Smaller heading */
    }
    #about p {
      font-size: 1rem; /* ✅ Readable on mobile */
    }
  }
  
  @media (max-width: 480px) {
    #about {
      width: 95vw; /* ✅ Utilizes more screen space */
      padding: 1rem;
    }
    #about h2 {
      font-size: 2.4rem; /* ✅ Adjusts for very small screens */
    }
    #about p {
      font-size: 1rem; /* ✅ Keeps text readable on phones */
    }
  }

  /* ✅ Image Description Styling */
.image-description {
  position: absolute;
  bottom: 20px; /* ✅ Adjust as needed */
  background: rgba(0, 0, 0, 0.5); /* ✅ Semi-transparent background */
  color: white; /* ✅ Ensures visibility */
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  max-width: 500px; /* ✅ Prevents overly wide text */
}

/* ✅ Responsive Tweaks */
@media (max-width: 768px) {
  .image-description {
      font-size: 0.8rem;
      max-width: 200px;
      right: 10px;
      bottom: 10px;
  }
}

@media (max-width: 480px) {
  .image-description {
      font-size: 0.7rem;
      max-width: 180px;
      right: 5px;
      bottom: 5px;
  }
}

  
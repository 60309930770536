/* ✅ Fix background image path */
.project-one-body {
    font-family: 'Arial', sans-serif;
    text-align: center;
    background: url('../public/assets/ProjectOneBg3.jpeg') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    padding: 20px;
    color: white;
    overflow-y: auto;
}


/* Outer Container */
.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh; /* ✅ Ensures full height */
    padding: 20px;
}


/* Glassmorphic Effect for the Container */
.container {
    max-width: 600px;
    width: 90%;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.001);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center; /* ✅ Centers content inside */
}


/* Headings */
h1 {
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 10px;
}

/* Canvas Styling */
.drawingCanvas {
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 300px;
    cursor: crosshair;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}

/* Button Group */
.button-group {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 12px;
}

/* Buttons */
button {
    padding: 12px 18px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    background: rgba(18, 18, 18, 0.3);
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

button:hover {
    background: rgba(0, 0, 0, 0.9);
    transform: translateY(-2px);
}

/* Output Text Area */
.outputText {
    width: 90%;
    height: 100px;
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    color: white;
}

/* Back Button - Glassmorphic */
.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.3);
    color: white;
}

.back-button:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        max-width: 90%;
        padding: 20px;
    }

    h1 {
        font-size: 24px;
    }

    button {
        font-size: 14px;
        padding: 10px 14px;
    }

    .drawingCanvas {
        height: 250px;
    }

    .back-button {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }
}

/* Image Upload Button */
.upload-input {
    display: block;
    margin: 15px auto;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    cursor: pointer;
}

/* Uploaded Image Preview */
.uploaded-image {
    max-width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
}

/* ✅ Mobile Responsive Adjustments */
@media (max-width: 768px) {
    .container {
        max-width: 90%;
        padding: 20px;
    }

    h1 {
        font-size: 24px;
    }

    button {
        font-size: 14px;
        padding: 10px 14px;
    }

    .drawingCanvas {
        height: 250px;
    }

    .back-button {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }
}

/* ✅ Mobile-Friendly Adjustments */
@media (max-width: 480px) {
    .project-one-body {
        background: url('../public/assets/ProjectOneBg3.jpeg') no-repeat center center;
        background-size: cover;
        background-attachment: fixed; /* ✅ Keeps background steady */
        padding: 10px;
        min-height: 100vh;
    }

    /* ✅ Outer Container Adjustments */
    .outer-container {
        padding: 5px;
    }

    /* ✅ Shrink Glassmorphic Container */
    .container {
        max-width: 90%;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        text-align: center;
    }

    /* ✅ Reduce Heading Size */
    h1 {
        font-size: 18px;
        margin-bottom: 6px;
    }

    /* ✅ Shrink Canvas */
    .drawingCanvas {
        width: 100%;
        height: 200px; /* ✅ Reduce height for smaller screens */
        border-radius: 6px;
    }

    /* ✅ Adjust Buttons */
    button {
        font-size: 12px;
        padding: 8px 10px;
        border-radius: 6px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }

    /* ✅ Reduce Button Spacing */
    .button-group {
        flex-wrap: wrap;
        gap: 6px;
    }

    /* ✅ Shrink Output Text Area */
    .outputText {
        font-size: 12px;
        height: 60px;
        padding: 6px;
        border-radius: 6px;
        width: 90%;
    }

    /* ✅ Shrink File Upload */
    .upload-input {
        font-size: 12px;
        padding: 6px;
    }

    /* ✅ Reduce Back Button Size */
    .back-button {
        width: 30px;
        height: 30px;
        font-size: 10px;
        top: 10px;
        left: 10px;
    }

    /* ✅ Make Uploaded Image Fit Well */
    .uploaded-image {
        max-width: 100%;
        height: auto;
        border-radius: 6px;
    }
}

/* ✅ Fullscreen Container */
.project-one-descrip-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/* ✅ Back Button - Always on Top-Left */
.back-button {
    position: fixed;
    top: 15px;
    left: 15px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.3);
    color: white;
    z-index: 100; /* ✅ Ensures the button is always on top */
}

.back-button:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.1);
}

/* ✅ Parallax Background */
.parallax-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

/* ✅ Description Wrapper */
.description-wrapper {
    width: 80vw;
    max-width: 900px;
    padding: 2rem;
    text-align: center;
    color: white;
    z-index: 5;
}

/* ✅ Glassmorphic Description Card */
.description-card {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

/* ✅ Heading */
.description-card h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.2rem;
    font-weight: 300;
    color: #ffcc00;
}

/* ✅ Paragraph */
.description-card p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
    line-height: 1.6;
    color: #fff;
}

/* ✅ Try It Button */
.tryit-button {
    margin-top: 20px;
    padding: 12px 20px;
    font-size: 1.1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.3);
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    backdrop-filter: blur(8px);
}

.tryit-button:hover {
    background: rgba(255, 255, 255, 0.5);
    transform: scale(1.05);
}

/* ✅ 📱 Mobile Optimizations */
@media (max-width: 768px) {
    .description-wrapper {
        width: 90vw;
        padding: 1.5rem;
    }

    .description-card {
        padding: 25px;
    }

    .description-card h2 {
        font-size: 2rem;
    }

    .description-card p {
        font-size: 1.1rem;
    }

    .tryit-button {
        font-size: 1rem;
        padding: 10px 18px;
    }

    /* 📱 Smaller Back Button */
    .back-button {
        top: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 480px) {
    .description-wrapper {
        width: 95vw;
        padding: 1rem;
    }

    .description-card {
        padding: 20px;
    }

    .description-card h2 {
        font-size: 1.8rem;
    }

    .description-card p {
        font-size: 1rem;
    }

    .tryit-button {
        font-size: 0.9rem;
        padding: 8px 16px;
    }

    /* 📱 Even Smaller Back Button */
    .back-button {
        top: 8px;
        left: 8px;
        width: 35px;
        height: 35px;
    }
}

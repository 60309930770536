  /* ✅ CONTACT SECTION */
  #contact {
    width: 80vw;
    max-width: 900px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    margin: 0 auto;
    padding: 2rem;
    color: white;
  }
  
  /* ✅ CONTACT HEADING */
  #contact h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ffcc00;
    font-size: 2.8rem;
  }
  
  /* ✅ CONTACT TEXT */
  #contact p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    max-width: 100%;
    line-height: 1.6;
    text-align: left;
  }
  
  .parallax-bg-contact {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  /* ✅ SOCIAL ICONS */
  .social-icons {
    margin-top: 1rem;
    display: flex;
    gap: 20px;
  }
  
  .social-icon {
    font-size: 2rem;
    color: white;
    transition: color 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .social-icon:hover {
    color: #ffcc00;
    transform: scale(1.2);
  }

  
  /* ✅ MOBILE RESPONSIVENESS */
  @media (max-width: 1024px) {
    #contact {
      width: 85vw;
      padding: 1.5rem;
    }
    #contact h2 {
      font-size: 2.8rem;
    }
    #contact p {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 768px) {
    #contact {
      width: 90vw;
      padding: 1.2rem;
    }
    #contact h2 {
      font-size: 2.8rem;
    }
    #contact p {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    #contact {
      width: 95vw;
      padding: 1rem;
    }
    #contact h2 {
      font-size: 2.4rem;
    }
    #contact p {
      font-size: 1rem;
    }
  }
  
      /* ✅ Image Description Styling */
      .image-description {
        position: absolute;
        bottom: 20px; /* ✅ Adjust as needed */
        right: 20px; /* ✅ Position it on the right */
        background: rgba(0, 0, 0, 0.5); /* ✅ Semi-transparent background */
        color: white; /* ✅ Ensures visibility */
        padding: 8px 12px;
        border-radius: 5px;
        font-size: 0.9rem;
        font-family: 'Montserrat', sans-serif;
        text-align: right;
        max-width: 250px; /* ✅ Prevents overly wide text */
      }
      
      /* ✅ Responsive Tweaks */
      @media (max-width: 768px) {
        .image-description {
            font-size: 0.8rem;
            max-width: 200px;
            right: 10px;
            bottom: 10px;
        }
      }
      
      @media (max-width: 480px) {
        .image-description {
            font-size: 0.7rem;
            max-width: 180px;
            right: 5px;
            bottom: 5px;
        }
      }
      
    